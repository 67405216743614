// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import 'bootstrap-icons/font/bootstrap-icons.css';

@import 'flatpickr';

.inquiry-form input[type=checkbox], .inquiry-form select{
    width: 100%;
    border: unset;
    border-bottom: 2px solid #dadada;
    padding: 12px 0;
    font-size: 14px;
    outline: none;
    transition: 0.4s all;
}
.inquiry-form input[type=checkbox]{
    width: 15px;
    padding: 0px;
    border: 1px solid grey;
    margin-right: 10px;
}

.inquiry-form label {
    font-weight: 600;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

.inquiry-form .input-group span i::before{
    color: #ffd105;
    font-size: 16px;
    font-weight: 600;
}

.sec-title{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.sec-title h2{
    font-size: 36px;
    font-weight: 700;
}

.sec-title p{
    font-size: 16px;
}

.inquiry-form .input-group span i::before{
    color: #ffd105;
    font-size: 16px;
    font-weight: 600;
}
